/* global fit */
import lottie from 'lottie-web'

var aboutButton = document.querySelector('#about')
var playButton = document.querySelector('#play')
var returnButton = document.querySelector('#return')
var aboutText = document.querySelector('#aboutContainer')
var interactiveTitleContainer = document.querySelector('#interactiveTitleContainer')
var playerContainer = document.querySelector('#playerContainer')
var audioNodes = document.querySelectorAll('audio')
var songName = document.querySelector('#songName')
var nextButton = document.querySelector('#next')
var prevButton = document.querySelector('#prev')
var pauseButton = document.querySelector('#pause')
var picturesButton = document.querySelector('#pictures')
var picturesContainer = document.querySelector('#picturesContainer')
var frButton = document.querySelector('#fr')
var enButton = document.querySelector('#en')
var aboutFr = document.querySelector('#aboutFr')
var aboutEn = document.querySelector('#aboutEn')
var languages = document.querySelector('#languages')

var currentSong = 0
var playerIsActive = false

// var titles

// var params = {
//   container: document.getElementById('gkGifDesktop'),
//   renderer: 'svg',
//   loop: false,
//   autoplay: false,
//   path: '/content/1-home/data.json' // the path to the animation json
// }
// var anim

lottie.setQuality('low')

// anim = lottie.loadAnimation(params)

// anim.addEventListener('DOMLoaded', function () {
//   // titles = createInteractiveTitle()
// })

// document.addEventListener('DOMContentLoaded', function (event) {
//   var interactiveTitleContainer = document.querySelector('#interactiveTitleContainer')
//   var body = document.body
//   fit(interactiveTitleContainer, body, {
//     hAlign: fit.CENTER, // or fit.LEFT, fit.RIGHT
//     vAlign: fit.CENTER, // or fit.TOP, fit.BOTTOM
//     cover: true,
//     watch: true
//   })
// })

frButton.onclick = function () {
  aboutFr.style.display = 'block'
  aboutEn.style.display = 'none'
}

enButton.onclick = function () {
  aboutFr.style.display = 'none'
  aboutEn.style.display = 'block'
}

aboutButton.onclick = function () {
  languages.style.display = 'inline-flex'
  aboutText.style.display = 'block'
  playButton.style.display = 'none'
  picturesButton.style.display = 'block'
  aboutButton.style.display = 'none'
  if (playerIsActive) {
    playerContainer.style.display = 'none'
  }

  interactiveTitleContainer.style.display = 'none'
}

picturesButton.onclick = function () {
  languages.style.display = 'none'
  aboutText.style.display = 'none'
  returnButton.style.display = 'block'
  picturesButton.style.display = 'none'
  picturesContainer.style.display = 'block'
}

returnButton.onclick = function () {
  aboutButton.style.display = 'block'
  aboutText.style.display = 'none'
  playButton.style.display = 'block'
  returnButton.style.display = 'none'
  picturesButton.style.display = 'none'
  picturesContainer.style.display = 'none'
  if (playerIsActive) {
    playerContainer.style.display = 'block'
  }
  interactiveTitleContainer.style.display = 'block'
  if (window.innerWidth < 895) {
    if (playerIsActive) {
      interactiveTitleContainer.style.display = 'none'
    }
  }
}
playButton.onclick = function () {
  playButton.style.display = 'none'
  playerContainer.style.display = 'block'
  playerIsActive = true
  playSong(currentSong)
  if (window.innerWidth < 895) {
    interactiveTitleContainer.style.display = 'none'
  }
}

pauseButton.onclick = function () {
  playerContainer.style.display = 'none'
  playButton.style.display = 'block'
  playerIsActive = false
  if (window.innerWidth < 895) {
    interactiveTitleContainer.style.display = 'block'
  }
  pauseSong(currentSong)
}

nextButton.onclick = function () {
  playNextSong()
}

function playNextSong () {
  pauseSong(currentSong)
  currentSong = (currentSong + 1) >= audioNodes.length ? 0 : (currentSong + 1)
  loadSong(currentSong)
  playSong(currentSong)
}

prevButton.onclick = function () {
  pauseSong(currentSong)
  currentSong = (currentSong - 1) < 0 ? audioNodes.length - 1 : (currentSong - 1)
  loadSong(currentSong)
  playSong(currentSong)
}

function pauseSong (id) {
  var song = audioNodes[id]
  song.pause()
}

function loadSong (id) {
  var song = audioNodes[id]
  song.load()
}

function playSong (id) {
  var song = audioNodes[id]
  // var name = song.children[0].dataset.name.replace(/-/g, ' ')
  var name = song.children[0].dataset.name
  name = name.substr(name.indexOf('-', 0), name.length).replace(/-/g, ' ')
  name = name.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')

  song.play()
  song.onended = function () {
    playNextSong()
  }
  var currentSongToText = id + 1 < 10 ? ('0' + (id + 1)) : id + 1
  songName.innerHTML = '<p>' + currentSongToText + '</p>' + '<p>' + name + '</p>'
}

// function findFontDeltaBetweenTitles () {
//   var total = 0
//   var margin = 120
//   var delta = -100
//   var font = window.innerWidth < 895 ? (window.innerWidth) / 12 : 85
//   var fontDelta = 1

//   while (delta < 0) {
//     total = 0
//     for (var i = 0; i < font / fontDelta; i++) {
//       var titleHeight = font - i * fontDelta
//       total = total + titleHeight
//     }
//     delta = (window.innerHeight - margin) - total
//     fontDelta = fontDelta + 0.1
//   }
//   return fontDelta
// }

// function createInteractiveTitle () {
//   // var fontSizeOrigin = findFirstTitleFontSize()
//   var fontSizeOrigin = window.innerWidth < 895 ? (window.innerWidth) / 12 : 85
//   // var deltaFont = 5
//   var deltaFont = findFontDeltaBetweenTitles()
//   var prevTop = 0
//   var prevFontSize = 0
//   var maxNbOfTitles = fontSizeOrigin / deltaFont
//   var titles = []

//   for (var i = 0; i < maxNbOfTitles; i++) {
//     var p = document.createElement('p')
//     p.addEventListener('click', function () {
//       var gkGif = document.querySelector('#gkGifDesktop')
//       // var gifCanvas = document.querySelector('.canvid')
//       var body = document.body
//       fit(gkGif, body, {
//         hAlign: fit.CENTER, // or fit.LEFT, fit.RIGHT
//         vAlign: fit.CENTER, // or fit.TOP, fit.BOTTOM
//         cover: true,
//         watch: true
//       })
//       // fit(gifCanvas, gkGif, {
//       //   hAlign: fit.CENTER, // or fit.LEFT, fit.RIGHT
//       //   vAlign: fit.CENTER, // or fit.TOP, fit.BOTTOM
//       //   cover: true,
//       //   watch: true
//       // })
// //     canvidControl.play('clip1')

//       anim.goToAndPlay(0, true)
//     })

//     titles.push(p)

//     p.style.position = 'absolute'
//     var fontSize = fontSizeOrigin - deltaFont * i
//     p.style.fontSize = fontSize + 'px'
//     p.style.height = fontSize + 'px'
//     p.style.lineHeight = fontSize + 'px'

//     if (i !== 0) {
//       var top = prevFontSize + prevTop
//       p.style.top = top + 'px'
//       prevTop = top

//     // P = (1−t)2P1 + 2(1−t)tP2 + t2P3 //formumla for the bezize curve with 3 points
//     }
//     prevFontSize = fontSize
//     var t = document.createTextNode('GOLDEN CUCKOO')
//     p.appendChild(t)
//     interactiveTitleContainer.appendChild(p)
//   }
//   return {
//     titlesLists: titles,
//     maxNbOfTitles: maxNbOfTitles
//   }
// }

// if (window.innerWidth > 1400) {
  // window.addEventListener('mousemove', function (e) {
  //   var xNormalized = e.clientX / window.innerWidth
  //   var xCenteredNormalized = (e.clientX / window.innerWidth - 0.5)

  //   for (var i = 0; i < titles.titlesLists.length; i++) {
  //     var maxTitles = titles.maxNbOfTitles
  //     titles.titlesLists[i].style.left = 2 * (1 - (i / maxTitles)) * (i / maxTitles) * -1 * 1000 * xCenteredNormalized + (i / maxTitles) * 500 * xNormalized + 'px'
  //   }
  // }, false)
// }

// var resizeTimer
// window.addEventListener('resize', function () {
//   clearTimeout(resizeTimer)
//   resizeTimer = setTimeout(function () {
//     interactiveTitleContainer.innerHTML = ''
//     // titles = createInteractiveTitle()
//     if (window.innerWidth < 895) {
//       if (playerIsActive) {
//         interactiveTitleContainer.style.display = 'none'
//       }
//     }
//   }, 250)
// }, false)
